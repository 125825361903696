// a library to wrap and simplify api calls
import apisauce from "apisauce";
import qs from "qs";
// our "constructor"
const create = (baseURL = process.env.REACT_APP_BASE_URL) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  // debugger;
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    // 10 second timeout...
    timeout: 60000,
  });

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.

  const login = (email, password, grantType) =>
    api.post(
      "token",
      qs.stringify({
        username: email,
        password: password,
        grant_type: grantType,
      })
    );

  const createAdminUser = (token, first, last, email) =>
    api.post(
      "newAdminUser",
      {
        first,
        last,
        email,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

  const getAdminUsers = (token) =>
    api.get(
      "adminUsers",
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );

  const getUsers = (token) =>
    api.get(
      "Users",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

  const getUser = (userId, token) =>
    api.get(
      `User/${userId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const updateExpirationDate = (payload, token) => {
      return api.post(
        "updateExpirationDate",
        {
          ...payload,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json; charset=utf-8",
          },
  
          //   return  api.post("updateUserEmail", {"userId": userId, "email": email}, {
          //     headers: {
          //         Authorization: `Bearer ${token}`
          //       }
          //   })
          //
          //
          //
          //
        }
      );
    };

  const updateUserEmail = (payload, token) => {
    const { userId } = payload;
    const { email } = payload;

    return api.post(
      "updateUserEmail",
      { userId: userId, email: email },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const getRuns = (userId, token) => {
    const getString = `studentrun?userId=${userId}`;
    return api.get(
      getString,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const bumpRun = (userId, runId, token) => {
    const postString = `bumpRun/${userId}/${runId}`;
    return api.post(
      postString,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const abandonRun = (userId, runId, token) => {
    const postString = `abandonRun/${userId}/${runId}`;
    return api.post(
      postString,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const fetchClassrooms = (token) =>
    api.get(
      "Classroom",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  const fetchInstructors = (token) =>
    api.get(
      "Instructors",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

  const inactivateClassroom = (classroomId, token) => {
    const postString = `inactivateClassroom/${classroomId}`;
    return api.post(
      postString,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const runningClassroom = (classroomId,token) => {
    const postString = `isRunningClassroom/${classroomId}`;
    return api.post(
      postString,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  
  const registrationClassroom = (classroomId,token) => {
    const postString = `isRegistrationClassroom/${classroomId}`;
    return api.post(
      postString,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const setupNewClassroom = (payload, token) => {
    return api.post("classroomSetup", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
    });
  };

  const addSecondaryInstructor = (payload, token) => {
    return api.post("instructors/addSecondary", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
    });
  };

  const changePrimaryInstructor = (payload, token) => {
    return api.post("instructors/changePrimary", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
    });
  };

  // ADD_API_HANDLER

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    login,
    createAdminUser,
    getAdminUsers,
    getUsers,
    updateExpirationDate,
    updateUserEmail,
    getRuns,
    bumpRun,
    abandonRun,
    fetchInstructors,
    fetchClassrooms,
    getUser,
    setupNewClassroom,
    addSecondaryInstructor,
    changePrimaryInstructor,
    inactivateClassroom,
    runningClassroom,
    registrationClassroom
    // EXPORT_API_HANDLER
  };
};

// let's return back our create method as the default.
export default { create };
