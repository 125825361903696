import React, { useContext } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import { Badge, Row, Col } from "reactstrap";
import moment from "moment";

import { formatEnumValue } from "../../../../utilities/utils";

import { AgileContext } from "../../../../app/store/GlobalState";
import StyledBootstrapTable from "../../../../components/styled/BootstrapTable.js";
import StyledTableHeaderRow from "../../../../components/styled/StyledTableHeaderRow.js";

const AgileUsersTable = (props) => {
  const [state, dispatch] = useContext(AgileContext);

  const userIdLinkFormatter = (cell, row) => (
    <Link
      href={`/agile-user-overview/${row.id}`}
      className="btn btn-link"
      to={`/agile-user-overview/${row.id}`}>
      {cell}
    </Link>
  );

  const typeFormatter = (cell) => {
    switch (cell) {
      case 0:
      case "STUDENT":
        return <Badge color="primary">student</Badge>;
      case 80:
      case "80":
        return <Badge color="info">instructor as student</Badge>;
      case 89:
      case "INSTRUCTOR":
        return <Badge color="success">instructor</Badge>;
      case 99:
      case "99":
        return <Badge color="danger">SPL</Badge>;
      default:
        return cell;
    }
  };

  const emailFormatter = (cell) => {
    return cell;
  };
  const dateFormatter = (cell, row, formatType) => {
    const license = row.licenses[0];
    if (formatType === "registration" && license.registrationDate) {
      return moment(license.registrationDate).format("MM/DD/YYYY");
    } else if (formatType === "expiration" && license.expirationDate) {
      return moment(license.expirationDate).format("MM/DD/YYYY");
    } else {
      return "";
    }
  };

  if (state.loading) {
    return <span>Loading</span>;
  } else if (state.agileUsers === null || state.agileUsers.length < 1) {
    return <span>No records retrieved</span>;
  }

  const createCustomToolBar = (props) => {
    if (state.loading) {
      return <span>Loading</span>;
    }
    return (
      <Col>
        <StyledTableHeaderRow style={{ float: "right" }}>
          <Col sm="12" lg="6">
            <Row>{props.components.searchPanel}</Row>
          </Col>
        </StyledTableHeaderRow>
      </Col>
    );
  };
  const tableOptions = {
    toolbar: createCustomToolBar,
    clearSearch: true,
    sizePerPage: 10,
    onRowClick: function (row) {
      dispatch({
        type: "SET_CURRENT_USER",
        row,
      });
    },
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
    ],
  };
  return (
    <StyledBootstrapTable
      data={state.agileUsers}
      cellEdit={{ mode: "click", afterSaveCell: typeFormatter }}
      options={tableOptions}
      pagination
      hover
      search
      striped>
      <TableHeaderColumn
        isKey
        dataField="id"
        dataSort
        width="5%"
        dataFormat={userIdLinkFormatter}>
        ID
      </TableHeaderColumn>
      <TableHeaderColumn width="10%" editable={false} dataField="firstName">
        First Name
      </TableHeaderColumn>
      <TableHeaderColumn width="10%" dataField="lastName">
        Last Name
      </TableHeaderColumn>
      <TableHeaderColumn
        editable={false}
        dataField="email"
        dataSort
        dataFormat={emailFormatter}>
        Email
      </TableHeaderColumn>
      <TableHeaderColumn
        width="10%"
        dataField="licenses"
        dataSort
        dataFormat={(cell, row) => dateFormatter(cell, row, "registration")}>
        Start Date
      </TableHeaderColumn>
      <TableHeaderColumn
        width="10%"
        dataField="licenses"
        dataSort
        dataFormat={(cell, row) => dateFormatter(cell, row, "expiration")}>
        Expiration Date
      </TableHeaderColumn>
      <TableHeaderColumn
        width="10%"
        dataField="welcomeStatus"
        dataFormat={formatEnumValue}>
        Status
      </TableHeaderColumn>
      <TableHeaderColumn
        width="10%"
        dataField="role"
        dataFormat={typeFormatter}>
        Type
      </TableHeaderColumn>
    </StyledBootstrapTable>
  );
};

export default AgileUsersTable;
